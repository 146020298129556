<template>
  <div class="migration-log">
    {{ currentRow.item.message || $t("NoLogs") }}
  </div>
</template>
<script>
export default {
  components: {},
  props: ["currentRow"],
  data() {
    return {
      currentRow: null
    };
  },
  mounted() {
    this.currentRow = this.currentRow;
  },
  methods: {
    onClose() {
      this.$bvModal.hide("modal-migrate");
    }
  }
};
</script>
<style lang="scss">
.name-row {
  display: flex;
  align-items: center;
}
.migration-log {
  white-space: pre-line;
  max-height: 400px; /* Adjust the max height as needed */
  overflow-y: auto;
}
</style>
