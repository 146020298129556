<template>
  <div v-if="handlePage">
    <b-card class="mt-306 added-data">
      <div class="btn-row-migrate">
        <h4 class="card-title">
          {{ $t("migrate.MigrateTitle") }}
        </h4>
        <h6 class="card-subtitle text-muted">
          {{ $t("migrate.MigratesubTitle") }}
        </h6>
      </div>
      <b-card no-body class="mb-0">
        <b-table
          class="position-relative no-headers table-550"
          responsive
          show-empty
          align-v="end"
          :items="items"
          :fields="tableColumns"
          empty-text="No matching records found"
        >
          <!-- Column: User -->
          <!-- for dynamic in src make colon -->
          <template #cell(source)="data">
            <b-media class="align-item-center">
              <template #aside>
                <b-avatar
                  size="50"
                  src="@/assets/images/avatars/wialon.png"
                  text="R"
                  variant="light-primary"
                />
              </template>
              <h6 class="mb-0">{{ data.item.source }}</h6>
            </b-media>
          </template>
          <template #cell(connect_url)="data">
            <div class="text-right">
              <b-button
                :href="data.item.connect_url"
                variant="primary"
                v-show="!data.item.connected"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-10"
              >
                <span class="text-nowrap">{{
                  $t("migrate.connectwialonaccount")
                }}</span>
              </b-button>
              <b-button
                variant="primary"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-10"
                v-show="data.item.connected"
                @click="toggle(false, data.item.source)"
              >
                <span class="text-nowrap">{{ $t("migrate.Importdata") }}</span>
              </b-button>
              <b-button
                variant="outline-danger"
                v-show="data.item.connected"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-10"
                @click="disconnectMigrationAccount(data.item.source)"
              >
                <span class="text-nowrap">{{
                  $t("migrate.Disconnectaccount")
                }}</span>
              </b-button>
              <div v-show="false">
                <feather-icon icon="AlertCircleIcon" class="text-danger" />
                <small class="text-danger ml-6">{{
                  $t("migrate.ErrorWailon")
                }}</small>
              </div>
            </div>
          </template>
        </b-table>
        <div class="mx-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              &nbsp;
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalMigrations"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card>
    <Loader :show="showLoading" />
  </div>
  <div v-else>
    <MigrateExpand :toggle="toggle" :source="source" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import MigrateExpand from "./MigrateExpand.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MigrationService from "@/libs/api/migration-service";
import Loader from "@/layouts/components/Loader.vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    MigrateExpand,
    Loader
  },
  directives: {
    Ripple
  },
  data() {
    return {
      tableColumns: [
        { key: "source", label: this.$t("historyTableColumns.source") },
        {
          key: "connect_url",
          label: this.$t("historyTableColumns.connect_url")
        }
      ],
      selected: "10",
      option: ["10", "20", "30"],
      handlePage: true,
      showLoading: false,
      totalMigrations: 1,
      source: "",
      perPage: 10,
      currentPage: 1,
      items: []
    };
  },
  mounted() {
    this.getSupportedMigrations();
    if (this.$route.query.access_token) {
      this.saveMigrationToken(
        this.$route.query.access_token,
        this.$route.query.source
      );
      this.$router.push("/migrate");
    }
  },
  methods: {
    toggle(prop, source) {
      this.handlePage = prop;
      if (source) {
        this.source = source;
      }
    },
    async getSupportedMigrations(loader) {
      try {
        this.showLoading = loader || true;
        let response = await new MigrationService().getSupportedMigrations({});
        if (response && response.data) {
          this.items = response.data.migration_list;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = loader || false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async saveMigrationToken(token, source) {
      try {
        this.showLoading = true;
        let response = await new MigrationService().saveToken({
          token: token,
          source: source
        });
        if (response && response.data && response.data.success) {
          await this.getSupportedMigrations();
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async disconnectMigrationAccount(source) {
      this.showLoading = true;
      try {
        let response = await new MigrationService().disconnectMigrationAccount({
          source: source
        });
        if (response && response.data && response.data.success) {
          await this.getSupportedMigrations(this.showLoading);
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";
.btn-row-migrate {
  .card-subtitle.text-muted {
    margin-top: $px_10;
  }
}
.btn-row-migrate {
  padding-bottom: 12px;
  .card .card-title {
    margin-bottom: 1rem;
  }
}
.table-550{
  height: calc(100vh - 300px);
  overflow-y: auto;
  // overflow-x: hidden;
}
</style>
