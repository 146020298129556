<template>
  <b-card no-body class="mb-0">
    <div class="m-2-br-bottm migrtae-data-screenn">
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile-50"
          ><h4 class="card-title">
            {{ $t("migrate.Migrationdatafromwialon") }}
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0 mobile-50"
        >
          <b-button variant="primary" @click="importUnitsDevices()">
            <feather-icon icon="PlusIcon" size="12" />
            {{ $t("migrate.ImportItem") }}
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="m-2 m-2-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label class="search-label">{{ $t("device.Entries") }}</label>
          <v-select
            id="vue-select"
            :clearable="false"
            class="per-page-selector d-inline-block fix-83"
            v-model="perPage"
            @input="perPageChange()"
            :options="perPageOptions"
          />
        </b-col>
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        ></b-col>
        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <label class="search-label">{{ $t("message.seachLabel") }}</label>
            <b-form-input
              v-model="filter"
              debounce="1000"
              class="d-inline-block"
              :placeholder="$t('unit.SearchUnit')"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      :items="unit_lists"
      :fields="tableColumns"
      class="position-relative migrate-table migrate-table"
      responsive
      sticky-header
      show-empty
      align-v="end"
      empty-text="No matching records found"
    >
      <template #head(name)="data">
        <b-media>
          <template #aside>
            <b-form-checkbox
              :disabled="unit_lists.length == 0"
              v-model="selectAll"
              @change="toggleAllCheckBox()"
            >
            </b-form-checkbox>
          </template>
          <span>{{ data.label.toUpperCase() }}</span>
        </b-media>
      </template>
      <template #cell(name)="row">
        <div class="check-outer">
          <b-media class="align-cente-cust">
            <template #aside>
              <b-form-checkbox
                v-model="row.item.enable"
                @change="toggleCheckBox(row)"
              >
              </b-form-checkbox>
            </template>
            <h6 class="mb-0">{{ row.item.name }}</h6>
          </b-media>
        </div>
      </template>
      <template #cell(status)="row">
        {{ row.item.imported ? $t("Imported") : "" }}
      </template>
      <template #cell(actions)="row">
        <div class="text-right">
          <b-button
            variant="outline-info"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="ml-10"
            v-b-modal.modal-migrate
            @click="updateCurrentRow(row)"
          >
            <span class="text-nowrap">{{ $t("Details") }}</span>
          </b-button>
        </div>
      </template>
    </b-table>
    <div class="mx-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          &nbsp;
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentUnitPage"
            :total-rows="totalUnits"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal id="modal-migrate" centered modal-class="no-header-modal">
      <MigrateDetails :currentRow="currentRow" />
    </b-modal>
    <Loader :show="showLoading" />
  </b-card>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import MigrationService from "@/libs/api/migration-service";
import MigrateDetails from "./MigrationDetails.vue";
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BMedia,
  BFormInput,
  BPagination,
  BModal,
  BForm,
  BCardText
} from "bootstrap-vue";

export default {
  name: "MigrateExpand",
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    vSelect,
    BFormInput,
    BPagination,
    BModal,
    BForm,
    Loader,
    BCardText,
    MigrateDetails
  },
  directives: {
    Ripple
  },
  props: ["toggle"],
  watch: {
    currentUnitPage() {
      this.getUnitsDevices();
    },
    filter() {
      this.getUnitsDevices();
    }
  },
  mounted() {
    this.getUnitsDevices();
  },
  data() {
    return {
      selectAll: false,
      tableColumns: [
        { key: "name", label: this.$t("migrationTable.name") },
        { key: "status", label: this.$t("migrationTable.status") },
        {
          key: "actions",
          label: this.$t("migrationTable.actions")
        }
      ],
      perPage: 10,
      totalUnits: 1,
      currentUnitPage: 1,
      perPageOptions: [10, 50, 100, 500],
      unit_lists: [],
      showLoading: false,
      source: this.$parent.source,
      filter: "",
      currentRow: null
    };
  },
  methods: {
    updateCurrentRow(row) {
      this.currentRow = row;
    },
    perPageChange() {
      this.currentUnitPage = 1;
      this.getUnitsDevices();
    },
    async getUnitsDevices(row) {
      try {
        this.showLoading = true;
        let response = await new MigrationService().getUnitsDevices({
          filter: this.filter,
          source: this.source,
          unit_id: row && row.item && row.item.id ? row.item.id : "",
          unit: {
            page: this.currentUnitPage,
            page_size: this.perPage
          },
          device: {
            page:
              row && row.item && row.item.device_pagination.page
                ? row.item.device_pagination.page
                : 1,
            page_size: this.perPage
          }
        });
        if (
          response &&
          response.data &&
          Object.keys(response.data.unit_pagination).length > 0
        ) {
          this.unit_lists = response.data.unit_lists;
          this.totalUnits = response.data.unit_pagination.total_records;
          this.selectAll = this.unit_lists.every((item) => item.enable);
        } else if (
          response &&
          response.data &&
          Object.keys(response.data.unit_pagination).length == 0
        ) {
          return response;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async handlePageClick(row) {
      const response = await this.getUnitsDevices(row);
      if (
        response &&
        response.data &&
        response.data.unit_lists &&
        response.data.unit_lists.length > 0
      ) {
        this.unit_lists[row.index].device_lists =
          response.data.unit_lists[0]["device_lists"];
      }
      this.showLoading = false;
    },
    async toggleAllCheckBox() {
      this.unit_lists.forEach((item) => {
        item.enable = this.selectAll;
      });
      this.toggleCheckBox({
        item: {
          source: this.source,
          enable: this.selectAll
        }
      });
    },
    async toggleCheckBox(row) {
      try {
        this.showLoading = true;
        let response = await new MigrationService().toggleMigrations({
          id: row.item.id,
          source: row.item.source,
          enable: row.item.enable
        });
        this.selectAll = this.unit_lists.every((item) => item.enable);
        if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async importUnitsDevices() {
      try {
        this.showLoading = true;
        let response = await new MigrationService().importUnitsDevices({
          source: this.source
        });
        if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          this.showLoading = false;
        } else {
          this.getUnitsDevices();
        }
        this.showLoading = false;
      } catch (err) {
        this.showLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
$page-header-height: 270px;
.mx-2 {
  margin-bottom: 10px;
}
.b-table-sticky-header {
  max-height: calc(100vh - 315px) !important;
  min-height: calc(100vh - 315px) !important;
  overflow-y: auto;
}
.list-view-tab {
  .table-responsive {
    max-height: calc(100vh - 315px) !important;
    min-height: calc(100vh - 315px) !important;
    overflow-y: auto;
  }
}
</style>
