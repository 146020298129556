import APIService from "./api";

/** This class will include all services defined in migration-service */
export default class UnitService extends APIService {
  service = "migration-service";
  async getSupportedMigrations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getSupportedMigrations" },
      data,
      options
    );
  }
  async saveToken(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "saveToken" },
      data,
      options
    );
  }
  async disconnectMigrationAccount(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "disconnectMigrationAccount" },
      data,
      options
    );
  }
  async getUnitsDevices(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getUnitsDevices" },
      data,
      options
    );
  }
  async toggleMigrations(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "toggleMigrations" },
      data,
      options
    );
  }
  async importUnitsDevices(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "importUnitsDevices" },
      data,
      options
    );
  }
}
